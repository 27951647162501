import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-products-overview',
    templateUrl: './productsOverview.component.html',
    styleUrls: ['./productsOverview.component.scss']
})
export class ProductsOverviewComponent implements OnInit {
    loading: Boolean = false;
    constructor() { }

    ngOnInit() {
       
    }
}
