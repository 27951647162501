import {
    Component,
    OnInit,
    Output,
    EventEmitter,
    Input,
    OnDestroy
} from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
    selector: 'form-builder',
    templateUrl: './form-builder.component.html',
    styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent {
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onSubmit = new EventEmitter();
    // tslint:disable-next-line:no-output-on-prefix
    @Output() onUpdate = new EventEmitter();

    public _fields: any[] = [];

    @Input() set fields( fields : any[] ) {
        const fieldsCtrls = {};

        this._fields = fields;

        for (const f of fields) {

            if (f.type !== 'checkbox') {
                fieldsCtrls[f.name] = new FormControl(
                    f.value ? f.value : (f.value === false ? f.value : ''),
                    f.required ? [Validators.required, this.noWhitespaceValidator, this.numberValidator] : undefined
                );
            } else {

                const opts = {};
                for (const opt of f.options) {
                    opts[opt.key] = new FormControl(opt.value);
                }

                fieldsCtrls[f.name] = new FormGroup(opts);
            }
        }

        this.form = new FormGroup(fieldsCtrls);
    }

    @Input() isEdit: boolean;
    @Input() loading: boolean = false;

    form: FormGroup;
    unsubscribe: any;

    constructor() {

    }



    public noWhitespaceValidator(control: FormControl) {

        if (typeof control.value !== "string"){
            return null
        }

        const isWhitespace = (control.value || '').trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { 'whitespace': true };
    }

    public numberValidator( control:FormControl ) {

        if ( control.value === null ) {
            return { 'notanumber': true }
        }

        const value = control.value.imperial == control.value.imperial;

        if (typeof control.value.imperial !== 'number' ){
            return null
        } else if ( ! value ) { // test if NaN
            return { 'notanumber': true }
        } else {
            return null;
        }

    }

}
