import { Component } from '@angular/core';
import { AmplifyService } from 'aws-amplify-angular';
import { AuthService } from './services/auth.service';
import { Router } from '@angular/router';
import {Auth} from "aws-amplify";

import { interval } from 'rxjs/observable/interval';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent {

    private wasSignedIn = false;

    constructor(
        private amplifyService: AmplifyService,
        private authService: AuthService,
        private router: Router
    ) {

        // on app load, check if everything is still okay with current user.
        Auth.currentAuthenticatedUser({
            bypassCache: true
        }).then(user => {
            this.authService.setCurrentUser(user);
        }).catch(err => {
            this.authService.logout();
            // this.router.navigate(['/']);
        });

        const evry15mins = interval( 15 * 60 * 1000 );

        evry15mins.subscribe( () => {
            Auth.currentSession().then( data => {
                this.authService.setCurrentUser({ signInUserSession: data })
            }).catch( e => {
                console.log( "no current session" );
            } )
        });

        this.amplifyService.authStateChange$.subscribe(authState => {

            console.log( authState );

            if ( authState.state === 'signedIn' ) {
                this.authService.setCurrentUser(authState.user);
                this.wasSignedIn = true;
            }

            if ( this.wasSignedIn && authState.state === 'signedOut') {
                this.router.navigate(['/']);
            } else {

            }
        });
    }
}
