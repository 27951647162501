import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'field-builder',
    template: `
        <div class="form--group" [formGroup]="form">
            <div class="input--field">
                <label [attr.for]="field.label">
                    {{ field.label || 'Undefined label' }}
                    <strong class="text-danger" *ngIf="field.required"
                        >*</strong
                    >
                </label>
                <div [ngSwitch]="field.type">
                    <textbox
                        *ngSwitchCase="'string'"
                        [field]="field"
                        [form]="form"
                    ></textbox>
                    <number
                        *ngSwitchCase="'number'"
                        [field]="field"
                        [form]="form"
                    ></number>
                    <dropdown
                        *ngSwitchCase="'SingleSelect'"
                        [field]="field"
                        [form]="form"
                    ></dropdown>
                    <dropdown
                        *ngSwitchCase="'MultipleSelect'"
                        [field]="field"
                        [form]="form"
                        [multiple]="true"
                    ></dropdown>
                    <checkbox
                        *ngSwitchCase="'checkbox'"
                        [field]="field"
                        [form]="form"
                    ></checkbox>
                    <radio
                        *ngSwitchCase="'radio'"
                        [field]="field"
                        [form]="form"
                    ></radio>
                    <boolean
                        *ngSwitchCase="'boolean'"
                        [field]="field"
                        [form]="form"
                    ></boolean>
                    <metric-conversion
                        *ngSwitchCase="'metric_conversion_weight'"
                        [field]="field"
                        [form]="form"
                    ></metric-conversion>
                    <metric-conversion
                        *ngSwitchCase="'metric_conversion_distance'"
                        [field]="field"
                        [form]="form"
                    ></metric-conversion>
                    <metric-conversion
                        *ngSwitchCase="'metric_conversion_velocity'"
                        [field]="field"
                        [form]="form"
                    ></metric-conversion>
                </div>
                <span class="error" *ngIf="!isValid && isTouched">
                    {{ field.label || 'Undefined label' }} is required
                </span>
            </div>
        </div>
    `
})
export class FieldBuilderComponent {
    @Input() field: any;
    @Input() form: any;

    get isValid() {
        return this.form.controls[this.field.name]
            ? this.form.controls[this.field.name].valid
            : false;
    }

    get isTouched() {
        return this.form.controls[this.field.name]
            ? this.form.controls[this.field.name].touched
            : false;
    }
}
