import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_VALUES } from '../config/url.values';

@Injectable({
    providedIn: 'root'
})
export class ProductService {
    constructor(private http: HttpClient) { }

    createProduct(data) {
        return this.http.post(URL_VALUES.product, data);
    }

    getProducts(queryParams: any = {}) {
        const queryString = queryParams ? Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&') : '';
        return this.http.get(URL_VALUES.product + '?' + queryString);
    }

    getTotal(queryParams: any = {}) {
        const queryString = queryParams ? Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&') : '';
        return this.http.get(URL_VALUES.product + '/count?' + queryString);
    }

    getProduct(id) {
        return this.http.get(URL_VALUES.singleProduct(id));
    }

    getProductsByUserId(userId) {
        return this.http.get(`${URL_VALUES.product}?addedById=${userId}`);
    }

    updateProduct(id, data) {
        return this.http.patch(URL_VALUES.product + '/' + id, data);
    }

    activateProduct(id, active) {
        return this.http.patch( URL_VALUES.activateProduct(id), { active } );
    }

    deleteProduct(id) {
        return this.http.delete(URL_VALUES.product + '/' + id);
    }
}
