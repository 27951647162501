import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { URL_VALUES } from '../config/url.values';

@Injectable({
  providedIn: 'root'
})
export class FileService {
  constructor(private http: HttpClient) { }

  uploadFile(data) {
    const form = new FormData();
    form.append('file', data);
    return this.http.post(URL_VALUES.fileupload, form);
  }
}
