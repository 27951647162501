import { Component, OnInit, OnDestroy } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { CategoryService } from 'src/app/services/category.service';
import { AuthService } from 'src/app/services/auth.service';
import { Toaster } from 'ngx-toast-notifications';
import { FieldService } from 'src/app/services/field.service';
import { ProductService } from 'src/app/services/product.service';
import { FileService } from 'src/app/services/file.service';

@Component({
    selector: 'app-create-product',
    templateUrl: './createProduct.component.html',
    styleUrls: ['./createProduct.component.scss']
})
export class CreateProductComponent implements OnInit {
    form: FormGroup;
    unsubscribe: any = null;
    productName = '';
    loading: Boolean = false;
    image: any[] = [];
    file: any[] = [];
    errorImage = false;
    errorFile = false;

    fields: any[] = [];
    category: any = null;

    get getFields(): any[] {
        return this.fields || [];
    }

    constructor(
        private route: ActivatedRoute,
        private categoryService: CategoryService,
        private productService: ProductService,
        private fieldService: FieldService,
        private authService: AuthService,
        private toaster: Toaster,
        private fileService: FileService,
        private router: Router
    ) { }

    resetValues() {
        this.image = [];
        this.file = [];
        this.productName = '';
    }

    ngOnInit() {

        this.route.paramMap.subscribe((res: any) => {

            this.resetValues();

            this.categoryService
                .getCategory(res.params.id)
                .subscribe((category: any) => {

                    this.category = category;

                    this.fields = category.fields.sort((a, b) => {
                        return a.id - b.id
                    }).map(a => ({
                        ...a,
                        name: a.id
                    }));

                });
        });
    }

    submit(values) {

        if (this.productName === '' || this.productName === ' ') {
            return this.toaster.open({
                text: 'Product name is required.',
                type: 'danger'
            });
        }

        this.loading = true;
        // create fields
        const data = Object.entries(values).map(([key, value]) => {
            return {
                description: this.category.fields
                    .map(a => ({
                        ...a,
                        name: a.id
                    }))
                    .find(a => parseInt(a.name) === parseInt(key)).id,
                value: {
                    value
                }
            };
        });

        this.fieldService.createFieldValue(data).subscribe(async (res: any) => {
            const productPicture = await this.saveFile(this.image); //json string
            const productSpecifications = await this.saveFile(this.file); //json string
            const productData = {
                fields: res.map(a => a.id),
                category: this.category.id,
                name: this.productName,
                productPicture: productPicture,
                productSpecifications: productSpecifications,
                addedById: this.authService.currentUserValue.payload.username,
                addedByEmail: this.authService.currentUserValue.payload.email,
                addedByName: this.authService.currentUserValue.payload.name
                    ? this.authService.currentUserValue.payload.name
                    : this.authService.currentUserValue.payload.custom
                        .company_name
            };

            console.log( productData )

            // create product
            this.productService.createProduct(productData).subscribe(
                product => {
                    this.loading = false;
                    this.toaster.open({
                        text: 'Product has been added, thank you.',
                        type: 'success'
                    });

                    if ( this.router.url.includes('/app/admin/') ) {
                        this.router.navigate(['app/admin'])
                    } else {
                        this.router.navigate(['app/manufacturers'])
                    }
                    // this.router.navigate('')
                },
                err => {
                    this.loading = false;
                    this.toaster.open({
                        text: 'Failed to add product.',
                        type: 'danger'
                    });
                }
            );
        }, err => {
            console.log(err);
            this.toaster.open({
                text: 'Failed to add product.',
                type: 'danger'
            });
            this.loading = false;
        });
    }

    // is this even used?
    buildForm(data?: any) {
        this.form = new FormGroup({
            fields: new FormControl(JSON.stringify(data))
        });
    }

    onSelectImage(event) {
        this.errorImage = false;
        if (event.rejectedFiles.length > 0) {
            return this.errorImage = true;
        }
        this.image.splice(0, 1);
        this.image.push(...event.addedFiles);
    }

    onRemoveImage(event) {
        this.errorImage = false;
        this.image.splice(this.image.indexOf(event), 1);
    }
    onSelectFile(event) {
        this.errorFile = false;
        if (event.rejectedFiles.length > 0) {
            return this.errorFile = true;
        }
        this.file.splice(0, 1);
        this.file.push(...event.addedFiles);
    }

    onRemoveFile(event, temp) {
        this.errorFile = false;
        this.file.splice(this.file.indexOf(event), 1);
    }

    async saveFile(file: any[]) {
        if (file.length) {
            const res: any = await this.fileService.uploadFile(file[0]).toPromise();
            if (res.file.length > 0 && res.file_base_url) {
                return res.file_base_url + res.file[0].fd;
            }
        }
        return '';
    }
}
