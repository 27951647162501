import { Component, OnInit, HostListener, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-landing',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  @ViewChild('landingText', { static: false }) landingText: ElementRef;
  @ViewChild('landingBanner', { static: false }) landingBanner: ElementRef;
  public widthBanner: number;
  public widthText: number;

  constructor() { }
  ngOnInit() {
  }

  ngAfterContentChecked(): void {
    this.resize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.resize();
  }

  resize() {
    const padding = window.innerWidth < 656 ? 40 : 110;
    this.widthBanner = window.innerWidth > 1079 ? Math.round((window.innerWidth - padding) / 1.6) : 0;
    this.widthText = window.innerWidth > 1079 ? Math.round((window.innerWidth - padding) / 2.2) : (window.innerWidth - padding);
  }
}
