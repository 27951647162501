// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

// const awsmobile = {
//     "aws_project_region": "eu-central-1",
//     "aws_cognito_identity_pool_id": "eu-central-1:247c8b59-7782-465c-8d03-9c54ef6104ff",
//     "aws_cognito_region": "eu-central-1",
//     "aws_user_pools_id": "eu-central-1_NUEKwEq29",
//     "aws_user_pools_web_client_id": "4smscdlf070ri919dcd71otcli",
//     "oauth": {}
// };

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cognito_identity_pool_id": "eu-central-1:247c8b59-7782-465c-8d03-9c54ef6104ff",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_lFkvgx8lo",
    "aws_user_pools_web_client_id": "38lbs67kon58eobod0ngpernoq",
    "oauth": {}
};

export default awsmobile;
