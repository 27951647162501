import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastNotificationsModule } from 'ngx-toast-notifications';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { DataTablesModule } from 'angular-datatables';

import { DynamicFormBuilderModule } from './components/form-builder/form-builder.module';
import { HeaderComponent } from './components/header/header.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { CategoryListComponent } from './components/category-list/category-list.component';
import { DashboardHeaderComponent } from './components/dashboard-header/dashboard-header.component';
import { ProductListComponent } from './components/product-list/product-list.component';
import { CategoryService } from './services/category.service';
import { FileService } from './services/file.service';
import { AddCategoryComponent } from './pages/admin/add-category/add-category.component';
import { MainComponent } from './pages/landing/main/main.component';
import { AdminLayoutComponent } from './pages/admin/layout/layout.component';
import { UsersLayoutComponent } from './pages/manufacturer/layout/layout.component';
import { HasPermissionDirective } from './directives/has-permission.directive';
import { UserService } from './services/user.service';
import { CreateProductComponent } from './pages/manufacturer/product/create/createProduct.component';
import { ManufacturerComponent } from './pages/landing/manufacturer/manufacturer.component';
import { HomeComponent } from './pages/landing/home/home.component';
import { GripperComponent } from './pages/landing/gripper/gripper.component';
import { MRegistrationComponent } from './pages/manufacturer/m-registration/m-registration.component';
import { GRegistrationComponent } from './pages/gripper/g-registration/g-registration.component';
import { GRegistrationProfileComponent } from './pages/gripper/g-registration-profile/g-registration-profile.component';
import { UsersHomeComponent } from './pages/manufacturer/home/home.component';
import { LoginComponent } from './pages/landing/login/login.component';
import { ProductsOverviewComponent } from './pages/admin/products/productsOverview.component';
import { GripperActivationComponent } from './pages/admin/gripper-activation/gripper-activation.component';
import { GripperActivationListComponent } from './components/gripper-activation-list/gripper-activation-list.component';
import { FileComponent } from "./components/form-builder/fields/file";

import {
    AmplifyAngularModule,
    AmplifyModules,
    AmplifyService
} from 'aws-amplify-angular';
import Auth from '@aws-amplify/auth';
import { AuthGuard } from './guards/auth.guard';
import { AdminHomeComponent } from './pages/admin/home/home.component';
import { TokenInterceptor } from './helpers/jwt.interceptor';
import { ForgotPasswordComponent } from './pages/landing/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/landing/change-password/change-password.component';
import { UpdateProductComponent } from './pages/manufacturer/product/update/updateProduct.component';
import { FooterComponent } from './pages/landing/footer/footer.component';
import { TheAppComponent } from './pages/landing/the-app/the-app.component';
import { AboutUsComponent } from './pages/landing/about-us/about-us.component';
import { TermsAndPrivacyComponent } from './pages/landing/terms-and-privacy/terms-and-privacy.component';
import { AppStoreLinksComponent } from './pages/landing/app-store-links/app-store-links.component';

@NgModule({
    declarations: [
        AppComponent,
        HeaderComponent,
        HomeComponent,
        SidebarComponent,
        CategoryListComponent,
        AddCategoryComponent,
        MainComponent,
        AdminLayoutComponent,
        UsersLayoutComponent,
        HasPermissionDirective,
        CreateProductComponent,
        ManufacturerComponent,
        HomeComponent,
        GripperComponent,
        MRegistrationComponent,
        GRegistrationComponent,
        GRegistrationProfileComponent,
        UsersHomeComponent,
        LoginComponent,
        FileComponent,
        ProductsOverviewComponent,
        AdminHomeComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent,
        UpdateProductComponent,
        FooterComponent,
        TheAppComponent,
        AboutUsComponent,
        TermsAndPrivacyComponent,
        DashboardHeaderComponent,
        ProductListComponent,
        GripperActivationComponent,
        GripperActivationListComponent,
        AppStoreLinksComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        ReactiveFormsModule,
        FormsModule,
        DynamicFormBuilderModule,
        HttpClientModule,
        AutocompleteLibModule,
        NgSelectModule,
        AmplifyAngularModule,
        BrowserAnimationsModule,
        ToastNotificationsModule.forRoot({ position: 'top-right' }),
        NgxDropzoneModule,
        DataTablesModule
    ],
    providers: [
        CategoryService,
        FileService,
        UserService,
        AuthGuard,
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        {
            provide: AmplifyService,
            useFactory: () => {
                return AmplifyModules({
                    Auth
                });
            }
        }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
