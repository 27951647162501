import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Auth } from 'aws-amplify';
import { AuthService } from 'src/app/services/auth.service';
import {Toaster} from "ngx-toast-notifications";
import {ADMIN_GROUP, GRIPPER_GROUP, MANUFACTURER_GROUP} from "../../../constants/groups";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    form: FormGroup;
    loading = false;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private authService: AuthService,
        private toaster: Toaster
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            username: ['', Validators.required],
            password: ['', Validators.required]
        });
    }

    async login() {
        if (!this.form.valid) {
            return;
        }

        this.loading = true;
        const { username, password } = this.form.value;

        try {
            const user = await Auth.signIn(username, password);

            if (
                user.challengeName === 'SMS_MFA' ||
                user.challengeName === 'SOFTWARE_TOKEN_MFA'
            ) {
                // You need to get the code from the UI inputs
                // and then trigger the following function with a button click
                // we are not using MFA for GRIPLIST so this should not happen.
            } else if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {


                this.authService.setTempUser( user );
                this.router.navigate(['/change-password']);
                this.loading = false;

                // You need to get the new password and required attributes from the UI inputs
                // and then trigger the following function with a button click
                // For example, the email and phone_number are required attributes
                // const {username, email, phone_number} = getInfoFromUserInput();
                // const loggedUser = await Auth.completeNewPassword(
                //     user,              // the Cognito User Object
                //     newPassword,       // the new password
                //     // OPTIONAL, the required attributes
                //     {
                //         email,
                //         phone_number,
                //     }
                // );

            } else if (user.challengeName === 'MFA_SETUP') {
                // This happens when the MFA method is TOTP
                // The user needs to setup the TOTP before using it
                // More info please check the Enabling MFA part
                Auth.setupTOTP(user);
                this.loading = false;
            } else {
                this.loading = false;
                // The user directly signs in
                this.authService.setCurrentUser(user);

                const groups = this.authService.currentUserValue.payload["cognito:groups"];

                if ( ! groups ) {
                    this.router.navigate(['/']);
                } else if ( groups.indexOf( ADMIN_GROUP ) !== -1 ) {
                    this.router.navigate(['/app/admin']);
                } else if ( groups.indexOf( MANUFACTURER_GROUP ) !== -1 ) {
                    this.router.navigate(['/app/manufacturers']);
                } else if ( groups.indexOf( GRIPPER_GROUP ) !== -1 ) {
                    this.router.navigate(['app/gripper']);
                } else {
                    this.router.navigate(['/']);
                }

            }
        } catch (err) {

            this.toaster.open({
                text: err.message,
                type: 'danger'
            });

            this.loading = false;

            if (err.code === 'UserNotConfirmedException') {
                // The error happens if the user didn't finish the confirmation step when signing up
                // In this case you need to resend the code and confirm the user
                // About how to resend the code and confirm the user, please check the signUp part
            } else if (err.code === 'PasswordResetRequiredException') {
                // The error happens when the password is reset in the Cognito console
                // In this case you need to call forgotPassword to reset the password
                // Please check the Forgot Password part.
            } else if (err.code === 'NotAuthorizedException') {
                // The error happens when the incorrect password is provided
            } else if (err.code === 'UserNotFoundException') {
                // The error happens when the supplied username/email does not exist in the Cognito user pool
            } else {
                console.log(err);
            }
        }
    }
}
