import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { Auth } from 'aws-amplify';
import { AuthService } from 'src/app/services/auth.service';
import {Toaster} from "ngx-toast-notifications";
import {UserService} from "../../../services/user.service";
import {HttpClient} from "@angular/common/http";
import {URL_VALUES} from "../../../config/url.values";

@Component({
    selector: 'app-forgot-password',
    templateUrl: './forgot-password.component.html',
    styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
    form: FormGroup;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private toaster: Toaster,
        private http: HttpClient
    ) {}

    ngOnInit() {
        this.form = this.fb.group({
            email: ['', Validators.required]
        });
    }

    async submit() {
        if (!this.form.valid) {
            return;
        }

        const { email } = this.form.value;

        this.http.post( URL_VALUES.forgotPassword, { email } ).subscribe( result => {

            this.toaster.open({
                text: 'We have sent you instructions to reset your password',
                type: 'info'
            });

            this.router.navigate(['/login']);
        } )
    }
}
