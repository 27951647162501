import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { GripperService } from 'src/app/services/gripper.service';
import { Toaster } from 'ngx-toast-notifications';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-gripper-activation-list',
  templateUrl: './gripper-activation-list.component.html',
  styleUrls: ['./gripper-activation-list.component.scss']
})
export class GripperActivationListComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  @Input() isAdmin = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  loading: boolean = false;
  list: any = [];
  columns: any = [];

  constructor(private gripperService: GripperService, private toaster: Toaster, private router: Router) { }

  ngOnInit() {
    this.columns = [
      { data: 'firstName' },
      { data: 'lastName' },
      { data: 'email' },
      { data: 'createdAt' },
      { data: 'updatedAt' },
      { data: 'active' },
      { data: null, sortable: false },
    ]
    this.dtOptions = {
      pageLength: 50, //default like on the blueprint api settings
      serverSide: true,
      dom:"lr<'table-overflow't>ip",
      searching: false, //need to change data/count api to enable
      lengthChange: false, //need to change blueprint api interceptor to enable, because smallest length is 10
      ajax: async (dataTablesParameters: any, callback) => {
        const params = {
          limit: dataTablesParameters.length,
          skip: dataTablesParameters.start,
          sort: `${this.columns[dataTablesParameters.order[0].column].data} ${dataTablesParameters.order[0].dir}`
        }
        const total = await this.gripperService.getTotal().toPromise();
        this.list = await this.gripperService.getListGripper(params).toPromise();
        callback({
          recordsTotal: total,
          recordsFiltered: total,
          data: this.list
        });
      },
      columns: this.columns,
      order: [3, 'desc']
    };
  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }


  async toggleActive(id) {
    this.loading = true;
    const grip: any = await this.gripperService.getGripperByUserId(id).toPromise();
    if (grip.length) {
      const data = grip[0];
      const active = !data.active;
      const msg = active ? 'activated' : 'deactivated'
      this.gripperService.toggleActive(data.id, active).subscribe((res) => {
        this.loading = false;
        this.toaster.open({
          text: `${data.firstName} ${data.lastName} has been ${msg}, thank you.`,
          type: 'success'
        });
      }, err => {
        this.loading = false;
        this.toaster.open({
          text: `Failed to ${msg} ${data.firstName} ${data.lastName}.`,
          type: 'danger'
        });
      });
    }
    else {
      this.loading = false;
      this.toaster.open({
        text: `Grip not found.`,
        type: 'danger'
      });
    }
  }

  openCategory(event, id) {
    event.preventDefault();
    this.router.navigate(['/app/admin/data/edit', id]);
  }

  rerender() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    }
  }
}
