import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-layout',
    templateUrl: './layout.component.html',
    styleUrls: ['./layout.component.scss']
})
export class AdminLayoutComponent implements OnInit {
    isOpened = false;

    constructor() { }

    ngOnInit() { }

    openSidebar() {
        this.isOpened = !this.isOpened;
    }
}
