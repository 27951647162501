import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gripper-activation',
  templateUrl: './gripper-activation.component.html',
  styleUrls: ['./gripper-activation.component.scss']
})
export class GripperActivationComponent implements OnInit {
  constructor() { }

  ngOnInit() { }
}
