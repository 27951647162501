import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'boolean',
    template: `
        <div [formGroup]="form">
            <div class="control-group">
                <label>
                    <input
                        type="radio"
                        [value]="true"
                        [name]="field.name"
                        [formControlName]="field.name"
                        [checked]="field.value===true"
                    />
                    Yes
                </label>
            </div>
            <div class="control-group">
                <label>
                    <input
                        type="radio"
                        [value]="false"
                        [name]="field.name"
                        [formControlName]="field.name"
                        [checked]="field.value===false || field.value===''"
                    />
                    No
                </label>
            </div>
        </div>
    `,
    styles: [
        `
            :host > div {
                display: flex;
            }

            .form-radio {
                display: flex;
                align-items: center;
            }
        `
    ]
})
export class BooleanComponent {
    @Input() field: any = {};
    @Input() form: FormGroup;
}
