import { Injectable } from '@angular/core';
import { distinctUntilChanged } from 'rxjs/operators';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { URL_VALUES } from '../config/url.values';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private currentUserSubject = new BehaviorSubject<any>({});

    public currentUser = this.currentUserSubject
        .asObservable()
        .pipe(distinctUntilChanged());

    constructor(private http: HttpClient) {}

    setUser(user) {
        this.currentUserSubject.next(user);
    }

    addUserToGroup(group) {
        return this.http.post( URL_VALUES.addUserToGroup(group), null );
    }

    forgotPassword( email ) {
        return this.http.post( URL_VALUES.forgotPassword, { email } );
    }
}
