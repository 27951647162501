import { Component, OnInit, Input, ViewChild, AfterViewInit, OnDestroy } from '@angular/core';
import { ProductService } from 'src/app/services/product.service';
import { Toaster } from 'ngx-toast-notifications';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { AuthService } from "../../services/auth.service";
import {CategoryService} from "../../services/category.service";


@Component({
  selector: ' product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;
  @Input() isAdmin = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  loading: boolean = false;
  products: any = [];
  columns: any = [];
  userId: string;
  rootCategories: any[];

  constructor(private productService: ProductService, private toaster: Toaster, private router: Router, private authService: AuthService, private categories: CategoryService) { }

  ngOnInit() {

    // different data loader depending on admin or not

    let dataFetcher = (params) => {
      return this.productService.getProducts(params).toPromise();
    }

    let totalFetcher = () => {
      return this.productService.getTotal().toPromise();
    }

    let sortOrder;

    if (this.isAdmin) {
      this.columns = [
        { data: 'name' },
        { data: null, sortable: false },
        { data: 'category.name', sortable: false }, //need to change api to enable. keyword: Sort populated data on api
        { data: 'brandName', sortable: false },
        { data: 'addedByEmail'},
        { data: 'createdAt' },
        { data: 'updatedAt' },
        { data: null, sortable: false },
        { data: null, sortable: false },
        { data: null, sortable: false },
      ]

        sortOrder = [4,'desc'];
    }
    else {
      this.columns = [
        { data: 'name' },
        { data: null, sortable: false },
        { data: 'category.name', sortable: false }, //need to change api to enable. keyword: Sort populated data on api
        { data: 'createdAt' },
        { data: 'updatedAt' },
        { data: null, sortable: false },
        { data: null, sortable: false },
      ]

        sortOrder = [3,'desc'];


        dataFetcher = (params) => {
        params.addedById = this.userId
        return this.productService.getProducts(params).toPromise();
      }

      totalFetcher = () => {
        return this.productService.getTotal({
          addedById: this.userId
        }).toPromise();
      }

      this.userId = this.authService.currentUserValue.payload.username;
    }

    this.dtOptions = {
      pageLength: 50, //default like on the blueprint api settings
      serverSide: true,
      processing: true,
      dom:"lr<'table-overflow't>ip",
      searching: false, //need to change product/count api to enable
      lengthChange: false, //need to change blueprint api interceptor to enable, because smallest length is 10
      ajax: async (dataTablesParameters: any, callback) => {
        const params = {
          limit: dataTablesParameters.length,
          skip: dataTablesParameters.start,
          sort: `${this.columns[dataTablesParameters.order[0].column].data} ${dataTablesParameters.order[0].dir}`
        }
        const total = await totalFetcher();
        this.products = await dataFetcher(params);

        this.products.forEach( p => {
            // assume brand is the first value (moafu)
            p.brandName = p.fields[0].value.value;
        });

        console.log( this.products );

        callback({
          recordsTotal: total,
          recordsFiltered: total,
          data: this.products
        });
      },
      columns: this.columns,
        order: sortOrder
    };

    this.categories.findCategories( { isRoot: true } ).subscribe( (rootCats: any) => {
        this.rootCategories = rootCats;
    })

  }

  ngAfterViewInit() {
    this.dtTrigger.next();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }


  async toggleActive(id) {
    this.loading = true;
    const product: any = await this.productService.getProduct(id).toPromise();
    const active = !product.active;
    const msg = active ? 'activated' : 'deactivated'
    this.productService.activateProduct(id, active).subscribe((res) => {
      this.loading = false;
      this.toaster.open({
        text: `${product.name} has been ${msg}, thank you.`,
        type: 'success'
      });
    }, err => {
      this.loading = false;
      this.toaster.open({
        text: `Failed to ${msg} ${product.name}.`,
        type: 'danger'
      });
    });
  }

  openCategory(event, id) {
    event.preventDefault();
    this.router.navigate(['/app/admin/product/edit', id]);
  }

  deleteCategory(event, id) {
    if (window.confirm("Do you really want to delete this product?")) {
      this.loading = true;
      this.productService.deleteProduct(id).subscribe((res) => {
        this.rerender();
        this.loading = false;
        this.toaster.open({
          text: `Product has been deleted, thank you.`,
          type: 'success'
        });
      }, (err) => {
        this.loading = false;
        this.toaster.open({
          text: `Failed to delete product.`,
          type: 'danger'
        });
      })
    }
  }

  rerender() {
    if (this.dtElement && this.dtElement.dtInstance) {
      this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next();
      });
    }
  }

  getRootCatIcon( cat ) {

      const root = this.rootCategories.find( c => c.id === cat.parentCategory );

      return root.icon;
  }
}
