import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { UnitLookup } from '../../../constants/conversions.js';

@Component({
    selector: 'metric-conversion',
    template: `
        <div class="grid" [formGroup]="form">
            <div>
                <label [for]="'metric_' + field.name"
                    >Metric, {{ field.fieldData.unit.metric }}</label
                >
                <input
                    type="text"
                    [id]="'metric_' + field.name"
                    [name]="'metric_' + field.name"
                    (keyup)="metricChange($event)"
                    [placeholder]="field.placeholder || ''"
                    [(ngModel)]="metric"
                    [class.ng-invalid]="metricInvalid"
                    [ngModelOptions]="{ standalone: true }"
                />
            </div>
            <div>
                <label [for]="'imperial_' + field.name"
                    >Imperial, {{ field.fieldData.unit.imperial }}</label
                >
                <input
                    type="text"
                    [id]="'imperial_' + field.name"
                    [name]="'imperial_' + field.name"
                    (keyup)="imperialChange($event)"
                    [placeholder]="field.placeholder || ''"
                    [(ngModel)]="imperial"
                    [class.ng-invalid]="imperialInvalid"
                    [ngModelOptions]="{ standalone: true }"
                />
            </div>
        </div>
    `,
    styles: [
        `
            .grid {
                display: grid;
                grid-template-columns: repeat(2, 6fr);
                column-gap: 20px;
            }

            .grid label {
                font-size: 12px;
                font-weight: 400;
            }
        `
    ]
})
export class MetricConversionDistanceComponent implements OnInit {
    @Input() field: any = {};
    @Input() form: FormGroup;


    metric:number = null;
    imperial: number = null;
    metricInvalid = false;
    imperialInvalid = false;

    ngOnInit(): void {
        if (this.field.value) {
            this.metric = Math.round( parseFloat(this.field.value.metric) * 100 ) / 100;
            this.imperial = Math.round( parseFloat(this.field.value.imperial) * 100 ) / 100;
        }
    }

    metricChange(event) {

        const unit = this.field.fieldData.unit.metric;

        const value = event.target.value.replace(',', '.');

        if ( parseFloat(value) != value ) {
            this.form.controls[this.field.name].setErrors({'incorrect': true});
            this.metricInvalid = true;
            return
        } else {
            this.form.controls[this.field.name].setErrors(null);
            this.metricInvalid = false;
        }

        let imperialValue = null;

        if (!UnitLookup.metric[unit]) {
            console.log("don't know how to do conversion");
        } else {
            imperialValue =
                Math.round( parseFloat(value) * UnitLookup.metric[unit] * 100 ) / 100;
        }

        this.imperial = imperialValue;

        this.form.patchValue({
            [this.field.name]: {
                imperial: imperialValue,
                metric: parseFloat(value)
            }
        });
    }

    imperialChange(event) {
        const unit = this.field.fieldData.unit.imperial;

        const value = event.target.value.replace(',', '.');

        if ( parseFloat(value) != value ) {
            this.form.controls[this.field.name].setErrors({'incorrect': true});
            this.imperialInvalid = true;
            return;
        } else {
            this.form.controls[this.field.name].setErrors(null);
            this.imperialInvalid = false;
        }

        let metricValue = null;

        if (!UnitLookup.imperial[unit]) {
            console.log("don't know how to do conversion");
        } else {
            metricValue =
                Math.round(parseFloat(value) * UnitLookup.imperial[unit] * 100 ) / 100;
        }

        this.metric = metricValue;

        this.form.patchValue({
            [this.field.name]: {
                metric: metricValue,
                imperial: parseFloat(value)
            }
        });
    }
}
