import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Auth } from 'aws-amplify';
import { ToastConfig, Toaster, ToastType } from 'ngx-toast-notifications';

import { MANUFACTURER_GROUP } from '../../../constants/groups';
import { UserService } from "../../../services/user.service";
import { AuthService } from "../../../services/auth.service";
import { Router } from "@angular/router";

@Component({
    selector: 'app-m-registration',
    templateUrl: './m-registration.component.html',
    styleUrls: ['./m-registration.component.scss']
})
export class MRegistrationComponent implements OnInit {
    form: FormGroup;
    loading: Boolean = false;

    constructor(private fb: FormBuilder,
        private toaster: Toaster,
        private us: UserService,
        private authService: AuthService,
        private router: Router
    ) { }

    ngOnInit() {
        this.form = this.fb.group({
            email: [
                '',
                [Validators.required, Validators.email]
            ],
            password: ['', Validators.required],
            name: ['', Validators.required],
            company: ['', Validators.required],
            terms: [false, Validators.requiredTrue]
        });
    }

    submit() {

        if (this.form.valid) {
            this.loading = true;
            const values = this.form.value;

            // sign up
            Auth.signUp({
                username: values.email,
                password: values.password,
                attributes: {
                    email: values.email,
                    name: values.name,
                    ['custom:CompanyName']: values.company
                },
                validationData: [] //optional
            })
                .then(async data => {

                    try {
                        // sign in
                        const loggedInUser = await Auth.signIn({
                            username: values.email,
                            password: values.password,
                        });

                        // add to correct group
                        this.us.addUserToGroup(MANUFACTURER_GROUP).subscribe(res => {
                            // we need to refresh after updating the group
                            Auth.currentAuthenticatedUser({
                                bypassCache: true  // get latest data
                            }).then(user => {
                                this.loading = false;
                                this.authService.setCurrentUser(user);
                                this.router.navigate(['/app/manufacturers']);
                            }).catch(e => {
                                console.log(e);
                                this.loading = false;
                            });

                        }, err => {
                            console.log(err);
                            this.loading = false;
                        });

                    } catch (e) {
                        this.loading = false;
                        this.toaster.open({
                            text: 'You have been registered, but we could not log you in right now.',
                            type: 'danger'
                        });

                        this.toaster.open({
                            text: 'Please contact support.',
                            type: 'info'
                        });

                        this.router.navigate(['/login']);
                    }
                })
                .catch((err: any) => {
                    this.loading = false;
                    this.toaster.open({
                        text: err.message,
                        type: 'danger'
                    });
                });
        }
    }
}
