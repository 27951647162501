import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'radio',
    template: `
        <div [formGroup]="form">
            <div class="form-radio" *ngFor="let opt of field.options">
                <input
                    class="form-radio--input"
                    type="radio"
                    [value]="opt.key"
                    [name]="opt.name"
                    [formControlName]="field.name"
                />
                <label class="form-radio--label">
                    {{ opt.label }}
                </label>
            </div>
        </div>
    `
})
export class RadioComponent {
    @Input() field: any = {};
    @Input() form: FormGroup;

    constructor() {}
}
