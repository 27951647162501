import { Injectable } from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse
} from '@angular/common/http';
import { AuthService } from '../services/auth.service';
import { Observable } from 'rxjs';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(public auth: AuthService) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

        if( this.auth.currentUserValue ) {
            const token = this.auth.getToken();

            let newHeaders = req.headers;
            if (token) {
                newHeaders = newHeaders.append('Authorization', `Bearer ${token}`);
            }
            const authReq = req.clone({ headers: newHeaders });
            return next.handle(authReq);

        }


        return next.handle(req);

    }
}
