import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AddCategoryComponent } from './pages/admin/add-category/add-category.component';
import { MainComponent } from './pages/landing/main/main.component';
import { UsersLayoutComponent } from './pages/manufacturer/layout/layout.component';
import { CreateProductComponent } from './pages/manufacturer/product/create/createProduct.component';
import { HomeComponent } from './pages/landing/home/home.component';
import { ManufacturerComponent } from './pages/landing/manufacturer/manufacturer.component';
import { GripperComponent } from './pages/landing/gripper/gripper.component';
import { MRegistrationComponent } from './pages/manufacturer/m-registration/m-registration.component';
import { GRegistrationComponent } from './pages/gripper/g-registration/g-registration.component';
import { GRegistrationProfileComponent } from './pages/gripper/g-registration-profile/g-registration-profile.component';
import { GripperActivationComponent } from './pages/admin/gripper-activation/gripper-activation.component';
import { UsersHomeComponent } from './pages/manufacturer/home/home.component';
import { LoginComponent } from './pages/landing/login/login.component';
import { ProductsOverviewComponent } from './pages/admin/products/productsOverview.component';
import { AuthGuard } from './guards/auth.guard';
import { AdminLayoutComponent } from './pages/admin/layout/layout.component';
import { ForgotPasswordComponent } from './pages/landing/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './pages/landing/change-password/change-password.component';
import {
    MANUFACTURER_GROUP,
    GRIPPER_GROUP,
    ADMIN_GROUP
} from './constants/groups';
import { UpdateProductComponent } from './pages/manufacturer/product/update/updateProduct.component';
import { AboutUsComponent } from "./pages/landing/about-us/about-us.component";
import { TheAppComponent } from "./pages/landing/the-app/the-app.component";
import { TermsAndPrivacyComponent } from "./pages/landing/terms-and-privacy/terms-and-privacy.component";

const routes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },
    {
        path: 'forgot-password',
        component: ForgotPasswordComponent
    },
    {
        path: 'change-password',
        component: ChangePasswordComponent
    },
    {
        path: '',
        component: MainComponent,
        children: [
            {
                path: '',
                component: HomeComponent
            },
            {
                path: 'manufacturers',
                component: ManufacturerComponent
            },
            {
                path: 'grippers',
                component: GripperComponent
            },
            {
                path: 'register-as-manufacturer',
                component: MRegistrationComponent
            },
            {
                path: 'register-as-gripper',
                component: GRegistrationComponent
            },
            {
                path: 'about-us',
                component: AboutUsComponent
            },
            {
                path: 'the-app',
                component: TheAppComponent
            },
            {
                path: 'terms-privacy-policy',
                component: TermsAndPrivacyComponent
            }
        ]
    },
    {
        path: 'app/admin',
        component: AdminLayoutComponent,
        canActivate: [AuthGuard],
        data: { roles: [ADMIN_GROUP] },
        children: [
            {
                path: '',
                component: ProductsOverviewComponent,
                pathMatch: 'full'
            },
            {
                path: 'category/add-category',
                component: AddCategoryComponent
            },
            {
                path: 'category/:id',
                component: AddCategoryComponent
            },
            {
                path: 'product/edit/:id',
                component: UpdateProductComponent
            },
            {
                path: 'product/:id',
                component: CreateProductComponent
            },
            {
                path: 'gripper-activation',
                component: GripperActivationComponent
            }
        ]
    },
    {
        path: 'app/manufacturers',
        component: UsersLayoutComponent,
        canActivate: [AuthGuard],
        data: { roles: [MANUFACTURER_GROUP, ADMIN_GROUP] }, // Gripper
        children: [
            {
                path: '',
                component: UsersHomeComponent,
                pathMatch: 'full'
            },
            {
                path: 'product/edit/:id',
                component: UpdateProductComponent
            },
            {
                path: 'product/:id',
                component: CreateProductComponent
            }
        ]
    },
    {
        path: 'app/gripper',
        component: GRegistrationProfileComponent,
        canActivate: [AuthGuard],
        data: { roles: [GRIPPER_GROUP] }
    },
    {
        path: 'app/gripper/:id',
        component: GRegistrationProfileComponent,
        canActivate: [AuthGuard],
        data: { roles: [ADMIN_GROUP] }
    },

    { path: '**', redirectTo: "/" }
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
