import { Component, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { CategoryService } from 'src/app/services/category.service';
import { FileService } from 'src/app/services/file.service';
import { ActivatedRoute } from '@angular/router';
import { FieldService } from 'src/app/services/field.service';
import {
    IMPERIAL_UNITS,
    METRIC_UNITS,
    GENERAL_UNITS,
    matching_imperials,
    matching_metrics,
    units_per_quantity
} from 'src/app/constants/conversions';
import { Toaster } from "ngx-toast-notifications";

@Component({
    selector: 'app-add-category',
    templateUrl: './add-category.component.html',
    styleUrls: ['./add-category.component.scss']
})
export class AddCategoryComponent implements OnInit, OnChanges {
    keyword = 'name';
    form: FormGroup;
    icon: File[] = [];
    fields: any = [];
    arr: FormArray;
    categoryId = null;
    categoryIcon = null;
    errorIcon = false;
    isRoot = true;
    isEdit = false;

    imperialUnits = IMPERIAL_UNITS;
    metricUnits = METRIC_UNITS;
    generalUnits = GENERAL_UNITS;

    get fieldsArray(): FormArray {
        return this.form.get('fields') as FormArray;
    }

    constructor(
        private categoryService: CategoryService,
        private fieldService: FieldService,
        private fileService: FileService,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private toaster: Toaster
    ) { }

    ngOnInit() {
        this.buildForm();

        this.route.paramMap.subscribe((res: any) => {
            if (res.params.id) {
                this.categoryService
                    .getCategory(res.params.id)
                    .subscribe((category: any) => {
                        this.buildForm(category);
                        this.isEdit = true;
                    });
            }

            this.fieldService.getFieldDescription().subscribe(fields => {
                this.fields = fields;
            });
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        // console.log(changes);
    }

    selectEvent(item) {
        this.fieldsArray.push(
            this.initField({
                name: item.name,
                type: item.type,
                required: item.required,
                label: item.label || '',
                category: this.categoryId
            })
        );
    }

    setGeneralUnit(value, index) {
        this.fieldsArray.at(index).patchValue({
            unit: {
                conversion_needed: false,
                metric: value,
                imperial: value
            }
        });
    }


    addFieldOption( data, index ) {

        const options = this.fieldsArray.at(index).value.options ?
            this.fieldsArray.at(index).value.options : [];

        options.push( data.target.value );

        data.target.value = '';

        this.fieldsArray.at(index).patchValue({
            options
        });
    }

    removeFieldOption( i, x ) {

        const options = this.fieldsArray.at(i).value.options;

        options.splice( x, 1 );

        this.fieldsArray.at(i).patchValue({
            options
        });
    }

    async createCategory() {
        const icon = await this.saveIcon(this.icon[0]);
        this.categoryService
            .createCategory({ name: this.form.value.name, icon })
            .subscribe((res: any) => {
                this.categoryId = res.id;
                this.categoryIcon = icon;
                this.icon = [];
                this.errorIcon = false;
            });
    }

    async updateCategory() {
        if (this.icon.length) {
            const icon = await this.saveIcon(this.icon[0]);
            if (icon) {
                this.categoryIcon = icon;
                this.icon = [];
                this.errorIcon = false;
            }
        }
        this.categoryService
            .updateCategory(this.categoryId, {
                name: this.form.value.name,
                icon: this.categoryIcon
            })
            .subscribe((res: any) => {
                this.categoryId = res.id;
            });
    }

    addField() {
        this.fieldService.createFieldDescription([{
            name: "new field",
            type: "string",
            category: this.categoryId
        }]).subscribe( newField => {
            this.fieldsArray.push(this.initField(newField[0]));

            this.toaster.open({
                text: 'New field added to the bottom of the form.',
                type: 'success'
            })
        })
    }

    removeField(index: number, fieldId) {

        if( confirm("are you sure you want to delete this field, all data linked to field could be lost?") ) {
            this.fieldService.removeField( fieldId ).subscribe((data) => {
                console.log( data );

                this.toaster.open({
                    text: "field removed from form",
                    type: "info"
                })
            });

            const add = this.form.get('fields') as FormArray;
            add.removeAt(index);
        }


    }

    initField(data?: any) {

        let unit: boolean | any = false;
        if (data.fieldData && data.fieldData.unit) {
            unit = data.fieldData.unit;
        }

        let fieldDescriptionGroup;

        if (data) {
            fieldDescriptionGroup = this.fb.group({
                id: data.id,
                name: data.name,
                type: data.type,
                required: data.required,
                label: data.label,
                category: this.categoryId ? this.categoryId : data.category.id,
                unit: this.fb.group({
                    conversion_needed: unit
                        ? unit.conversion_needed
                        : false,
                    imperial: unit ? unit.imperial : '',
                    metric: unit ? unit.metric : ''
                }),
                options: data.fieldData && data.fieldData.options ? [data.fieldData.options] : [[]]
            });
        } else {
            fieldDescriptionGroup = this.fb.group({
                name: '',
                type: '',
                required: false,
                label: '',
                category: this.categoryId,
                unit: this.fb.group({
                    conversion_needed: false,
                    imperial: '',
                    metric: ''
                }),
                options: []
            });
        }


        // I'm new to reactive forms, I want to deal with data changes
        // this is very verbose , is there a cleaner way to deal with this??
        fieldDescriptionGroup.get('unit').get('metric').valueChanges.subscribe((selectedMetric) => {

            if (fieldDescriptionGroup.get('unit').get('conversion_needed').value) {
                fieldDescriptionGroup.get('unit').get('imperial').setValue(matching_metrics[selectedMetric])
            }
        });

        fieldDescriptionGroup.get('unit').get('imperial').valueChanges.subscribe((selectedImperial) => {
            const metric = fieldDescriptionGroup.get('unit').get('metric').value;

            if (fieldDescriptionGroup.get('unit').get('conversion_needed').value && metric !== matching_imperials[selectedImperial]) {
                fieldDescriptionGroup.get('unit').get('metric').setValue(matching_imperials[selectedImperial])
            }
        });

        fieldDescriptionGroup.get("type").valueChanges.subscribe((selectedValue) => {
            if (selectedValue === "number") {
                fieldDescriptionGroup.get("unit").get("conversion_needed").setValue(false);
                fieldDescriptionGroup.get("unit").get("metric").setValue('');
            } else if (['metric_conversion_weight', 'metric_conversion_distance', 'metric_conversion_velocity']
                .indexOf(selectedValue) !== -1) {
                fieldDescriptionGroup.get("unit").get("conversion_needed").setValue(true);
                fieldDescriptionGroup.get("unit").get("metric").setValue('');
            }
        });

        return fieldDescriptionGroup;

    }

    getMetricUnits(type) {
        return units_per_quantity.metrics[type.replace('metric_conversion_', '')];
    }

    getImperialUnits(type) {
        return units_per_quantity.imperial[type.replace('metric_conversion_', '')];
    }

    submit() {
        this.fieldService
            .createFieldDescription(this.form.value.fields)
            .subscribe(res => {
                // console.log(res);
            });
    }

    update() {

        const fieldsToUpdate = this.form.value.fields.map(f => ({
            ...f,
            fieldData: {
                ...f.fieldData,
                unit: f.unit,
                options: f.options
            }
        }));

        this.fieldService
            .updateFieldDescription({
                fields: fieldsToUpdate
            })
            .subscribe(res => {
                // console.log(res);
                this.toaster.open({
                    text: 'Category form has been updated, thank you',
                    type: 'success'
                });
            });
    }

    buildForm(data?: any) {
        this.form = this.fb.group({
            name: '',
            fields: this.fb.array([])
        });
        this.categoryIcon = null;
        if (data) {
            this.categoryId = data.id;
            this.categoryIcon = data.icon;
            this.isRoot = data.isRoot;
            this.form = this.fb.group({
                name: data.name,
                fields: this.fb.array(data.fields.sort((a, b) => { return a.id - b.id }).map(a => this.initField(a)))
            });
        }
    }

    onSelectIcon(event) {
        this.errorIcon = false;
        if (event.rejectedFiles.length > 0) {
            return this.errorIcon = true;
        }
        this.icon.splice(0, 1);
        this.icon.push(...event.addedFiles);
    }

    onRemoveIcon(event) {
        this.errorIcon = false;
        this.icon.splice(this.icon.indexOf(event), 1);
    }

    async saveIcon(file: File) {
        const icon: any = await this.fileService.uploadFile(file).toPromise();
        if (icon.file.length > 0 && icon.file_base_url) {
            return icon.file_base_url + icon.file[0].fd;
        }
        return '';
    }
}
