import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_VALUES } from '../config/url.values';

@Injectable({
    providedIn: 'root'
})
export class CategoryService {
    constructor(private http: HttpClient) {}

    getCategories() {
        return this.http.get(URL_VALUES.categories);
    }

    findCategories( queryParams : any ) {
        const queryString = queryParams ? Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&') : '';
        return this.http.get(URL_VALUES.categories + '?' + queryString);
    }

    getCategory(id) {
        return this.http.get(URL_VALUES.category(id));
    }

    createCategory(data) {
        return this.http.post(URL_VALUES.categories, data);
    }

    updateCategory(id, data) {
        return this.http.patch(URL_VALUES.category(id), data);
    }

    deleteCategory(id) {
        return this.http.delete(URL_VALUES.category(id));
    }
}
