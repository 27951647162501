import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'app-main',
    templateUrl: './main.component.html',
    styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
    isLoggedIn = null;
    visibleMobile = false;

    constructor(private authService: AuthService, private router: Router) {
        this.authService.currentUser.subscribe(user => {
            if (user === null) {
                this.isLoggedIn = false;
            } else {
                this.isLoggedIn = true;
            }
        });
    }

    ngOnInit() {
        this.isLoggedIn = this.authService.currentUserValue;
    }

    goToDash() {
        const user = this.authService.currentUserValue;

        if (user.payload['cognito:groups'].includes('Admin')) {
            this.router.navigate(['/app/admin']);
        } else if (user.payload['cognito:groups'].includes('Manufacturer')) {
            this.router.navigate(['/app/manufacturers']);
        } else if (user.payload['cognito:groups'].includes('Gripper')) {
            this.router.navigate(['/app/gripper']);
        } else {
            this.router.navigate(['/login']);
        }

        return false;

        // if (typeof this.authService.currentUserValue.signInUserSession.accessToken.payload[
        //     'cognito:groups'
        //     ] === 'undefined' ) return false;
        //
        //
        // return this.authService.currentUserValue.signInUserSession
        //     ? this.authService.currentUserValue.signInUserSession.accessToken.payload[
        //           'cognito:groups'
        //       ].includes('Admin')
        //     : false;
    }

    openMenu() {
        this.visibleMobile = !this.visibleMobile;
    }

    closeMenu() {
        this.visibleMobile = false;
    }
}
