import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// components
import { FormBuilderComponent } from './form-builder.component';
import { FieldBuilderComponent } from './field-builder/field-builder.component';
import { TextBoxComponent } from './fields/textbox';
import { DropDownComponent } from './fields/dropdown';
import { CheckBoxComponent } from './fields/checkbox';
import { RadioComponent } from './fields/radio';
import { BooleanComponent } from './fields/boolean';
import { NgSelectModule } from '@ng-select/ng-select';
import { MetricConversionDistanceComponent } from './fields/metric_conversion';
import { NumberComponent } from './fields/number';

@NgModule({
    imports: [CommonModule, ReactiveFormsModule, NgSelectModule, FormsModule],
    declarations: [
        FormBuilderComponent,
        FieldBuilderComponent,
        TextBoxComponent,
        DropDownComponent,
        CheckBoxComponent,
        RadioComponent,
        BooleanComponent,
        MetricConversionDistanceComponent,
        NumberComponent
    ],
    exports: [FormBuilderComponent],
    providers: []
})
export class DynamicFormBuilderModule {}
