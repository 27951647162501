import { Injectable } from '@angular/core';
import {
    Router,
    CanActivate,
    ActivatedRouteSnapshot,
    RouterStateSnapshot
} from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    private allowedRoles: string[];

    constructor(private router: Router, private authService: AuthService) {}

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        this.allowedRoles = route.data['roles'];

        const currentUser = this.authService.currentUserValue;

        if (currentUser) {
            // check if route is restricted by role
            if (
                !this.authService.currentUserValue.payload['cognito:groups']
                || this.authService.currentUserValue.payload['cognito:groups']
                    .filter(role => this.allowedRoles.includes(role)).length === 0
            ) {
                // todo :: set some kind of access denied message
                // role not authorised so redirect to home page
                this.router.navigate(['/']);
                return false;
            }

            // authorised so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], {
            queryParams: { returnUrl: state.url }
        });
        return false;
    }
}


