import {Component, NgZone, OnInit} from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { FileService } from 'src/app/services/file.service';
import { GripperService } from 'src/app/services/gripper.service';
import { Toaster } from 'ngx-toast-notifications';
import { countries } from 'src/app/constants/countries';
import { AuthService } from "../../../services/auth.service";
import {HttpClient} from "@angular/common/http";

// bit lazy to put it here =)
const g_api_key = "AIzaSyABMgZm5mvMK_YKp1YI0ZHg2Mf7BodVzHk";
const places_api_uri = "https://maps.googleapis.com/maps/api/place/autocomplete/json"


@Component({
    selector: 'app-g-registration-profile',
    templateUrl: './g-registration-profile.component.html',
    styleUrls: ['./g-registration-profile.component.scss']
})
export class GRegistrationProfileComponent implements OnInit {
    form: FormGroup;
    image: File[] = [];
    profileImage: String = null;
    countries: Array<Object> = countries;
    isEdit: Boolean = false;
    loading: Boolean = false;
    mongoId: String = null;
    locations: any[] = [];
    googlePlacesSession: string = '';
    googleAutoCompleteService: any;
    gripper: any;

    gripperFunctions = [
        'Key Grip', 'Grip', 'Best Boy', 'Rigging Grip', 'Head Tech', 'Dolly Grip', 'Crane Grip', 'RA Operator', 'Precision Driver', 'Drone Pilot'
    ]

    constructor(private fb: FormBuilder,
        private fileService: FileService,
        private gripperService: GripperService,
        private toaster: Toaster,
        private auth: AuthService,
        private route: ActivatedRoute,
        private location: Location,
        private ngZone: NgZone,
    ) {

        // @ts-ignore
        this.googleAutoCompleteService = new google.maps.places.AutocompleteService();

        // @ts-ignore
        this.googlePlacesSession = new google.maps.places.AutocompleteSessionToken();

    }

    async ngOnInit() {
        this.form = this.fb.group({
            profilePicture: '',
            firstName: [this.auth.currentUserValue.payload.name, Validators.required],
            lastName: ['', Validators.required],
            functions: ['', Validators.required],
            country: [countries[0].code, Validators.required],
            city: ['', Validators.required],
            email: [this.auth.currentUserValue.payload.email, Validators.required],
            phoneNumber: '',
            imdbProfile: '',
        });

        this.route.paramMap.subscribe(async (res: any) => {
            const id = res.params.id ? res.params.id : this.auth.currentUserValue.payload.username;
            const gripper: any = await this.gripperService.getGripperByUserId(id).toPromise();
            if (gripper.length) {
                this.isEdit = true;
                this.mongoId = gripper[0].id;
                this.profileImage = gripper[0].profilePicture;
                this.gripper = gripper[0];
                this.form = this.fb.group({
                    profilePicture: gripper[0].profilePicture,
                    firstName: [gripper[0].firstName, Validators.required],
                    lastName: [gripper[0].lastName, Validators.required],
                    functions: [JSON.parse(gripper[0].functions), Validators.required],
                    country: [gripper[0].country, Validators.required],
                    city: gripper[0].city,
                    email: [gripper[0].email, Validators.required],
                    phoneNumber: gripper[0].phoneNumber,
                    imdbProfile: gripper[0].imdbProfile,
                });
            }
            else if (this.auth.currentUserValue.payload['cognito:groups'].indexOf('Gripper') == -1) {
                //if gripper not found and logged user is not gripper, redirect to last page
                this.location.back();
            }
        });
    }

    async submit() {

        if ( ! this.form.valid ) {
            return this.toaster.open({
                text: 'Form not valid.',
                type: 'danger'
            });
        }

        this.loading = true;
        if (this.image.length) {
            const image = await this.saveImage(this.image[0]);
            this.form.value.profilePicture = image;
        }

        const values = this.form.value;

        values.functions = JSON.stringify( values.functions );

        this.gripperService.createGripper(values).subscribe((res: any) => {
            this.loading = false;
            this.isEdit = true;
            this.mongoId = res.id;
            this.profileImage = res.profilePicture;
            this.image = [];
            this.toaster.open({
                text: 'Grip has been added,thank you.',
                type: 'success'
            });
        }, err => {
            console.log(err);
            this.loading = false;
            this.toaster.open({
                text: 'Failed to add grip.',
                type: 'danger'
            });
        })
    }

    public locationSearch( data ) {

        if (data.term.length > 2) {
            this.googleAutoCompleteService.getPlacePredictions({
                input: data.term,
                types: ['(cities)'],
                sessionToken: this.googlePlacesSession
            }, ( data, status ) => {
                if ( status === 'OK' ) {
                    this.ngZone.run( () => {
                        this.locations = data;
                    })
                }
            });
        }

    }

    public selectLocation(location) {
        if ( location.terms.length > 1 ) {
            this.form.patchValue({
                city: location.terms[0].value,
                country: location.terms[location.terms.length - 1].value
            });
        }
    }

    async update() {
        if ( ! this.form.valid ) {
            return this.toaster.open({
                text: 'Form not valid.',
                type: 'danger'
            });
        }

        const values = this.form.value;

        if ( values.functions.length === 0 ) {
            return this.toaster.open({
                text: 'Please add at least 1 function.',
                type: 'danger'
            });
        }

        values.functions = JSON.stringify( values.functions );

        this.loading = true;
        if (this.image.length) {
            const image = await this.saveImage(this.image[0]);
            this.form.value.profilePicture = image;
        }
        this.gripperService.updateGripper(this.mongoId, values).subscribe((res: any) => {
            this.profileImage = res.profilePicture;
            this.image = [];
            this.loading = false;
            this.toaster.open({
                text: 'Grip has been updated,thank you.',
                type: 'success'
            });
        }, err => {
            console.log(err);
            this.loading = false;
            this.toaster.open({
                text: 'Failed to update grip.',
                type: 'danger'
            });
        })
    }

    onSelectImage(event) {
        this.image.splice(0, 1);
        this.image.push(...event.addedFiles);
    }

    onRemoveImage(event) {
        this.image.splice(this.image.indexOf(event), 1);
    }

    async saveImage(file: File) {
        const image: any = await this.fileService.uploadFile(file).toPromise();
        if (image.file.length > 0 && image.file_base_url) {
            return image.file_base_url + image.file[0].fd;
        }
        return '';
    }

}
