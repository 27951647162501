const KG_TO_POUND = 2.20462;
const GRAM_TO_OUNCE = 0.035274;
const KM_TO_MILES = 0.621371;
const KMH_TO_MPH = 0.621371;
const CM_INCH = 0.393701;
const MM_TO_INCH = 0.0393701;
const MPS_TO_FEET_PER_SECOND = 3.28084;
const DEGREES_TO_RADIANS = 0.0174533;
const M_TO_FEET = 3.280839895;

export const UnitLookup = {
    metric: {
        kg: KG_TO_POUND,
        g: GRAM_TO_OUNCE,
        mm: MM_TO_INCH,
        m: M_TO_FEET,
        km: KM_TO_MILES,
        kmh: KMH_TO_MPH,
        mps: MPS_TO_FEET_PER_SECOND
    },
    imperial: {
        pounds: 1 / KG_TO_POUND,
        ounce: 1 / GRAM_TO_OUNCE,
        inch: 1 / MM_TO_INCH,
        feet: 1 / M_TO_FEET,
        miles: 1 / KM_TO_MILES,
        mph: 1 / KMH_TO_MPH,
        mps: 1 / MPS_TO_FEET_PER_SECOND
    }
};

// unit list
export const METRIC_UNITS = ['kg', 'g', 'km', 'm', 'mm', 'kmh', 'ms'];
export const IMPERIAL_UNITS = ['pounds', 'ounce', 'miles', 'feet', 'inch', 'mph', 'fps'];
export const GENERAL_UNITS = ['°C', 'Watt', 'Volt'];

export const units_per_quantity = {
    metrics: {
        weight: METRIC_UNITS.slice(0,2),
        distance: METRIC_UNITS.slice(2,5),
        velocity: METRIC_UNITS.slice(5,7)
    },
    imperial: {
        weight: IMPERIAL_UNITS.slice(0,2),
        distance: IMPERIAL_UNITS.slice(2,5),
        velocity: IMPERIAL_UNITS.slice(5,7)
    }
};

export const matching_metrics = {
    'kg': 'pounds',
    'g': 'ounce',
    'km': 'miles',
    'mm': 'inch',
    'm': 'feet',
    'kmh': 'mph',
    'ms': 'fps',
    '': ''
};

export const matching_imperials = {
    'pounds': 'kg',
    'ounce': 'g',
    'miles': 'km',
    'inch': 'mm',
    'feet': 'm',
    'mph': 'kmh',
    'fps': 'ms',
    '': ''
};
