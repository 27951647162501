import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-manufacturer',
  templateUrl: './manufacturer.component.html',
  styleUrls: ['./manufacturer.component.scss']
})
export class ManufacturerComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
