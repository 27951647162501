import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'checkbox',
    template: `
        <div [formGroup]="form">
            <div [formGroupName]="field.name">
                <div
                    *ngFor="let opt of field.options; let i = index"
                    class="form-checkbox"
                >
                    <label class="form-checkbox--label">
                        <input
                            class="form-checkbox--input"
                            [formControlName]="opt.key"
                            type="checkbox"
                            [id]="'inlineCheckbox' + i"
                            [value]="opt.value"
                        />
                        {{ opt.label }}
                    </label>
                </div>
            </div>
        </div>
    `
})
export class CheckBoxComponent {
    @Input() field: any = {};
    @Input() form: FormGroup;
}
