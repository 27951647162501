import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'number',
    template: `
        <div [formGroup]="form">
            <label [for]="field.name">{{ field.fieldData.unit.metric }}</label>
            <input
                type="number"
                class="form-textbox"
                [id]="field.name"
                [name]="field.name"
                [formControlName]="field.name"
                [placeholder]="field.placeholder || ''"
            />
        </div>
    `
})
export class NumberComponent {
    @Input() field: any = {};
    @Input() form: FormGroup;

    constructor() {}
}
