export const BASE = 'https://api.griplist.com';
// export const BASE = 'http://localhost:1337';

export const URL_VALUES = {
    categories: `${BASE}/category`,
    product: `${BASE}/product`,
    activateProduct: id => `${BASE}/product/${id}/activate`,
    singleProduct: id => `${BASE}/product/${id}/full`,
    fielddescription: `${BASE}/fielddescription`,
    fielddescriptionmany: `${BASE}/fielddescription/many`,
    fielddescriptionupdatemany: `${BASE}/fielddescription/updateMany`,

    fieldvalue: `${BASE}/fieldvalue/many`,
    fieldvalueUpdateMany: `${BASE}/fieldvalue/updateMany`,

    category: id => `${BASE}/category/${id}`,
    addUserToGroup: group => `${BASE}/user/${group}`,
    forgotPassword: `${BASE}/user/resetPassword`,
    fileupload: `${BASE}/files/upload`,
    grippers: `${BASE}/gripper`,
    gripper: id => `${BASE}/gripper/${id}`,
    gripperByUserId: id => `${BASE}/gripper?userId=${id}`,
    gripperActiveToggle: id => `${BASE}/gripper/${id}/activate`,
};
