import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from 'src/app/services/auth.service';
import { Toaster } from 'ngx-toast-notifications';
import { ProductService } from 'src/app/services/product.service';
import { FieldService } from 'src/app/services/field.service';
import { FileService } from 'src/app/services/file.service';
import * as _ from "lodash";

@Component({
    selector: 'app-product',
    templateUrl: './updateProduct.component.html',
    styleUrls: ['./updateProduct.component.scss']
})
export class UpdateProductComponent implements OnInit {
    form: FormGroup;
    unsubscribe: any = null;
    productName = '';

    fields: any[] = [];
    product: any = null;
    isEdit = false;
    loading: boolean = false;
    file: any[] = [];
    image: any[] = [];
    errorImage = false;
    errorFile = false;
    productPicture: String = '';
    productSpecifications: String = '';

    get getFields(): any[] {
        return this.fields || [];
    }

    constructor(
        private route: ActivatedRoute,
        private fieldService: FieldService,
        private productService: ProductService,
        private authService: AuthService,
        private toaster: Toaster,
        private fileService: FileService,
    ) { }

    ngOnInit() {

        this.route.paramMap.subscribe((res: any) => {
            this.productService
                .getProduct(res.params.id)
                .subscribe((product: any) => {
                    this.product = product;
                    this.productName = this.product.name;
                    this.isEdit = true;

                    this.fields = product.fields
                        .filter(f => f.description) // it's possible that the description is not found or no longer exists...
                        .sort((a, b) => {
                        return a.description.id - b.description.id
                    }).map(a => ({
                        ...a.description,
                        value: a.value.value,
                        name: a.id
                    }));

                    this.productPicture = this.product.productPicture;
                    this.productSpecifications = this.product.productSpecifications;
                    this.buildForm(this.fields);
                });
        });
    }

    async update(values) {
        this.loading = true;

        //remove file and image from fields because we will store it on product table
        let product_picture = [];
        let product_spec = [];
        if (typeof values.product_picture != 'undefined') {
            product_picture = values.product_picture;
            delete values.product_picture;
        }
        if (typeof values.product_spec != 'undefined') {
            product_spec = values.product_spec;
            delete values.product_spec;
        }

        // update fields
        const updateData = _.map(values, (value, id) => ({
            id,
            value: {
                value
            }
        }));

        this.fieldService.updateManyFieldValues({ fields: updateData }).subscribe(
            product => {
                this.toaster.open({
                    text: 'Product fields have been updated, thank you.',
                    type: 'success'
                });
            },
            err => {
                this.loading = false;
                this.toaster.open({
                    text: 'Failed to update product fields.',
                    type: 'danger'
                });
            }
        );

        const productPicture = await this.saveFile(this.image, this.productPicture);
        const productSpecifications = await this.saveFile(this.file, this.productSpecifications);
        // update items directly on product model
        const productUpdate = {
            name: this.productName,
            productPicture: productPicture,
            productSpecifications: productSpecifications
        }

        this.productService.updateProduct(this.product.id, productUpdate).subscribe((p) => {
            this.loading = false;
            this.productPicture = productPicture;
            this.productSpecifications = productSpecifications;
            this.image = [];
            this.file = [];
            this.errorImage = false;
            this.errorFile = false;
            this.toaster.open({
                text: 'Product has been updated, thank you.',
                type: 'success'
            });
        }, err => {
            this.loading = false;
            this.toaster.open({
                text: 'Failed to update product.',
                type: 'danger'
            });
        });

    }

    buildForm(data?: any) {
        this.form = new FormGroup({
            fields: new FormControl(JSON.stringify(data))
        });
    }

    // Deprecated
    // I don't think we need submit function on update component?
    submit(values) {
        const data = Object.entries(values).map(([key, value]) => {
            return {
                description: this.product.fields
                    .map(a => ({
                        ...a,
                        name: a.name
                            .split('.')
                            .join('')
                            .replace(/[^a-zA-Z ]/g, '')
                            .replace(/\s+/g, '_')
                            .toLowerCase()
                    }))
                    .find(a => a.name === key).id,
                value: {
                    value
                }
            };
        });

        this.fieldService.createFieldValue(data).subscribe((res: any) => {
            const productData = {
                fields: res.map(a => a.id),
                category: this.product.category.id,
                name: this.productName,
                addedById: this.authService.currentUserValue.username,
                addedByName: this.authService.currentUserValue.attributes.name
                    ? this.authService.currentUserValue.attributes.name
                    : this.authService.currentUserValue.attributes.custom
                        .company_name
            };

            this.productService.createProduct(productData).subscribe(
                product => {
                    this.toaster.open({
                        text: 'Product has been added, thank you.',
                        type: 'success'
                    });
                },
                err => {
                    this.toaster.open({
                        text: 'Failed to add product.',
                        type: 'danger'
                    });
                }
            );
        });
    }

    onSelectImage(event) {
        this.errorImage = false;
        if (event.rejectedFiles.length > 0) {
            return this.errorImage = true;
        }
        this.image.splice(0, 1);
        this.image.push(...event.addedFiles);
    }

    onRemoveImage(event) {
        this.errorImage = false;
        this.image.splice(this.image.indexOf(event), 1);
    }
    onSelectFile(event) {
        this.errorFile = false;
        if (event.rejectedFiles.length > 0) {
            return this.errorFile = true;
        }
        this.file.splice(0, 1);
        this.file.push(...event.addedFiles);
    }

    onRemoveFile(event, temp) {
        this.errorFile = false;
        this.file.splice(this.file.indexOf(event), 1);
    }

    async saveFile(file: any[], existing: String = '') {
        if (file.length) {
            const res: any = await this.fileService.uploadFile(file[0]).toPromise();
            if (res.file.length > 0 && res.file_base_url) {
                return res.file_base_url + res.file[0].fd;
            }
        }
        return existing;
    }
}
