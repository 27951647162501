import { Component, OnInit } from '@angular/core';
import {
    FormGroup,
    FormBuilder,
    Validators,
    AbstractControl
} from '@angular/forms';
import { Router } from '@angular/router';

import { Auth } from 'aws-amplify';
import { AuthService } from 'src/app/services/auth.service';
import {Toaster} from "ngx-toast-notifications";

@Component({
    selector: 'app-change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
    form: FormGroup;

    constructor(
        private fb: FormBuilder,
        private router: Router,
        private authService: AuthService,
        private toaster: Toaster
    ) {}

    ngOnInit() {

        this.form = this.fb.group(
            {
                name: [this.authService.tempUser.challengeParam.userAttributes.name, Validators.required],
                password: ['', Validators.required],
                confirmPassword: ['', Validators.required]
            },
            { validator: this.passwordConfirming }
        );
    }

    passwordConfirming(c: AbstractControl): { invalid: boolean } {
        if (c.get('password').value !== c.get('confirmPassword').value) {
            return { invalid: true };
        }
    }

    async submit() {
        if (!this.form.valid) {
            return;
        }

        const { password, name } = this.form.value;

        try {

            try {
                const user = await Auth.completeNewPassword(
                    this.authService.tempUser,
                    password,
                    {
                        name
                    }
                );

                if (user) {
                    this.authService.setCurrentUser(user);
                    this.router.navigate(['/']);
                }
            } catch (e) {
                console.log( e );

                return this.toaster.open({
                    text: e.message,
                    type: 'danger'
                });
            }


        } catch (err) {

            console.log( err );

            return this.toaster.open({
                text: err,
                type: 'danger'
            });
        }
    }
}
