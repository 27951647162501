import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-app-store-links',
  templateUrl: './app-store-links.component.html',
  styleUrls: ['./app-store-links.component.scss']
})
export class AppStoreLinksComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
