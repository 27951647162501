import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'textbox',
    template: `
        <div [formGroup]="form">
            <input
                *ngIf="!field.multiline"
                type="text"
                class="form-textbox"
                [id]="field.name"
                [name]="field.name"
                [formControlName]="field.name"
                [placeholder]="field.placeholder || ''"
            />
            <textarea
                *ngIf="field.multiline"
                [class.is-invalid]="isDirty && !isValid"
                [formControlName]="field.name"
                [id]="field.name"
                rows="9"
                class="form-textbox"
                [placeholder]="field.placeholder || ''"
            ></textarea>
        </div>
    `
})
export class TextBoxComponent {
    @Input() field: any = {};
    @Input() form: FormGroup;

    constructor() {}
}
