import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserService } from "../../../services/user.service";
import { AuthService } from "../../../services/auth.service";
import { Toaster } from "ngx-toast-notifications";
import { Auth } from "aws-amplify";
import { GRIPPER_GROUP } from '../../../constants/groups';


@Component({
    selector: 'app-g-registration',
    templateUrl: './g-registration.component.html',
    styleUrls: ['./g-registration.component.scss']
})
export class GRegistrationComponent implements OnInit {
    form: FormGroup;
    loading: Boolean = false;

    constructor(private fb: FormBuilder,
        private router: Router,
        private us: UserService,
        private toaster: Toaster,
        private authService: AuthService
    ) { }

    ngOnInit() {
        this.form = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
            name: ['', Validators.required],
            terms: [false, Validators.requiredTrue]
        });
    }

    async register() {

        if (this.form.valid) {
            this.loading = true;
            const values = this.form.value;

            // try to register
            try {
                const user = await Auth.signUp({
                    username: values.email,
                    password: values.password,
                    attributes: {
                        email: values.email,
                        name: values.name,
                    }
                });
            } catch (err) {
                this.loading = false;
                return this.toaster.open({
                    text: err.message,
                    type: 'danger'
                });
            }

            // try to log in
            try {
                const loggedInUser = await Auth.signIn({
                    username: values.email,
                    password: values.password,
                });

            } catch (e) {
                this.loading = false;
                this.toaster.open({
                    text: 'You have been registered, but we could not log you in right now.',
                    type: 'danger'
                });

                this.toaster.open({
                    text: 'Please contact support.',
                    type: 'info'
                });

                return this.router.navigate(['/login']);
            }

            // add to group
            this.us.addUserToGroup(GRIPPER_GROUP).subscribe(res => {

                // we need to refresh after updating the group
                Auth.currentAuthenticatedUser({
                    bypassCache: true  // get latest data
                }).then(user => {
                    this.loading = false;
                    this.authService.setCurrentUser(user);
                    this.router.navigate(['/app/gripper']);
                }).catch(e => {
                    console.log(e);
                    this.loading = false;
                });

            }, err => {
                this.loading = false;
                console.log(err);
            });

        }

    }
}
