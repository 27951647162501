import { Component, OnInit, Input } from '@angular/core';
import { CategoryService } from 'src/app/services/category.service';

@Component({
    selector: 'app-category-list',
    templateUrl: './category-list.component.html',
    styleUrls: ['./category-list.component.scss']
})
export class CategoryListComponent implements OnInit {
    @Input() isAdmin = false;

    categories = [];
    isVisible = false;
    isProductsVisible = false;

    constructor(private categoryService: CategoryService) { }

    ngOnInit() {
        this.getCategories();
    }

    getCategories() {
        this.categoryService.getCategories().subscribe((categories: any) => {
            const subcategories = categories.filter(a => !a.isRoot);
            this.categories = categories
                .filter(a => a.isRoot)
                .map(b => ({
                    ...b,
                    subcategories: subcategories.filter(
                        c => c.parentCategory.id === b.id
                    ),
                    subcatvisible: false
                }));
        });
    }

    deleteCategory(id, event, index) {
        event.stopPropagation();
        this.categories.splice(index, 1);
        this.categoryService.deleteCategory(id).subscribe(res => {
            // console.log(res);
        });
    }

    showCategories() {
        this.isVisible = !this.isVisible;
    }

    showProducts() {
        this.isProductsVisible = !this.isProductsVisible;
    }

    toggleSubCat(catIndex) {
        this.categories[catIndex].subcatvisible = !this.categories[catIndex].subcatvisible
    }
}
