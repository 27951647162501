import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Auth } from 'aws-amplify';

@Injectable({
    providedIn: 'root'
})
export class AuthService {
    public currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;
    public tempUser: any;

    constructor() {
        const user = JSON.parse(localStorage.getItem('user'));

        this.currentUserSubject = new BehaviorSubject<any>(user);
        this.currentUser = this.currentUserSubject.asObservable();

        this.currentUserSubject.subscribe(val => {
            localStorage.setItem('user', JSON.stringify(val));
        });
    }

    public setCurrentUser(user) {
        user.signInUserSession.idToken.payload = {
            ...user.signInUserSession.accessToken.payload,
            ...user.signInUserSession.idToken.payload
        };

        // console.log("latest access token ====");
        // console.log( user.signInUserSession.idToken.jwtToken );

        this.currentUserSubject.next(user.signInUserSession.idToken);
    }

    public get currentUserValue(): any {
        return this.currentUserSubject.value;
    }

    public getToken(): string {
        const currentUser = this.currentUserSubject.getValue();
        return currentUser.jwtToken;
    }

    public setTempUser( user ) {
        this.tempUser = user;
    }

    async logout() {
        this.currentUserSubject.next(null);

        await Auth.signOut();
    }
}
