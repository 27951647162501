import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_VALUES } from '../config/url.values';

@Injectable({
  providedIn: 'root'
})
export class GripperService {
  constructor(private http: HttpClient) { }

  createGripper(data) {
    return this.http.post(URL_VALUES.grippers, data);
  }
  updateGripper(id, data) {
    return this.http.patch(URL_VALUES.gripper(id), data);
  }
  getGripperByUserId(id) {
    return this.http.get(URL_VALUES.gripperByUserId(id));
  }
  getListGripper(queryParams: any = {}) {
    const queryString = queryParams ? Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&') : '';
    return this.http.get(URL_VALUES.grippers + '?' + queryString);
  }
  getTotal(queryParams: any = {}) {
    const queryString = queryParams ? Object.keys(queryParams).map(key => key + '=' + queryParams[key]).join('&') : '';
    return this.http.get(URL_VALUES.grippers + '/count?' + queryString);
  }
  toggleActive(id, active) {
    return this.http.patch(URL_VALUES.gripperActiveToggle(id), { active });
  }
}
