import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL_VALUES } from '../config/url.values';

@Injectable({
    providedIn: 'root'
})
export class FieldService {
    constructor(private http: HttpClient) {}

    getFieldDescription() {
        return this.http.get(URL_VALUES.fielddescription);
    }

    createFieldDescription(data) {
        return this.http.post(URL_VALUES.fielddescriptionmany, data);
    }

    removeField( id ) {
        return this.http.delete(URL_VALUES.fielddescription + `/${id}`)
    }

    updateFieldDescription(data) {
        return this.http.post(URL_VALUES.fielddescriptionupdatemany, data);
    }

    createFieldValue(data) {
        return this.http.post(URL_VALUES.fieldvalue, data);
    }

    updateManyFieldValues( newValues ) {
        return this.http.patch( URL_VALUES.fieldvalueUpdateMany, newValues );
    }


}
