import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
    selector: 'dropdown',
    template: `
        <div [formGroup]="form">
            <ng-select
                [multiple]="multiple"
                appearance="outline"
                [labelForId]="field.name"
                [searchable]="false"
                [formControlName]="field.name"
            >
                <ng-option
                    *ngFor="let opt of field.fieldData.options"
                    [value]="opt"
                    >{{ opt }}</ng-option
                >
            </ng-select>
        </div>
    `
})
export class DropDownComponent {
    @Input() field: any = {};
    @Input() form: FormGroup;
    @Input() multiple = false;

    constructor() {}
}
