import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
    @Input() isAdmin = false;
    @Input() isOpened = false;
    @Output("toggleSidebar") toggleSidebar: EventEmitter<any> = new EventEmitter();

    constructor(private authService: AuthService) { }

    ngOnInit() { }
    toggle() {
        this.toggleSidebar.emit();
    }
}
