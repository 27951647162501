import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../services/auth.service';
import { ProductService } from 'src/app/services/product.service';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class UsersHomeComponent implements OnInit {
    products: any = [];
    constructor(
        private productService: ProductService,
        private authService: AuthService
    ) {}

    ngOnInit() {
        const user = this.authService.currentUserValue;
        this.productService
            .getProductsByUserId(user.payload.sub)
            .subscribe(res => {
                this.products = res;
            });
    }
}
